import { useFormikContext } from "formik";
import { ComponentPropsWithoutRef } from "react";
import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";

type NewAppLoadingSubmitButtonProps = ComponentPropsWithoutRef<"button"> & {
  label?: string;
  testid?: string;
  className?: string;
  isLoading?: boolean;
};

const NewAppLoadingSubmitButton = ({
  label,
  testid,
  className,
  isLoading,
  ...props
}: NewAppLoadingSubmitButtonProps) => {
  const { handleSubmit, isSubmitting, status } = useFormikContext();

  const handleClick = async () => {
    handleSubmit();
  };

  return (
    <>
      <button
        data-testid={testid}
        type="button"
        className={`button-regular-general-filled w-10 ${className ?? ""}`}
        disabled={isSubmitting || isLoading}
        onClick={handleClick}
        {...props}
      >
        {isSubmitting || isLoading ? (
          <Spinner spinnerSize={SpinnerSize.Small} />
        ) : (
          <>
            {label && label}
            {props.children}
          </>
        )}
      </button>
      {status?.error && (
        <div className="text-status-danger text-sm">{status.error}</div>
      )}
      {status?.success && (
        <div className="text-status-success text-sm">{status.success}</div>
      )}
    </>
  );
};

export default NewAppLoadingSubmitButton;
