import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import RouteConfigWrapper, {
  HubRouteConfig,
} from "hub/src/routes/RouteConfigWrapper";
import { Redirect, Route, RouteProps, useLocation } from "react-router";

type Props = RouteProps & {
  children?: React.ReactNode;
  checkPermission?: boolean;
  routeConfig?: HubRouteConfig;
};

const ProtectedRoute: React.FC<Props> = ({
  children,
  render,
  routeConfig,
  ...props
}) => {
  const hubSessionContext = useHubSessionContext();
  const location = useLocation();

  if (!hubSessionContext.societyUserId) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  //Public Routes are publicly advertised but still may have permission tags so we need to check for permission tags again.
  if (!hubSessionContext.adminMode) {
    if (
      routeConfig?.everyTag?.length !== 0 ||
      routeConfig?.someTag?.length !== 0 ||
      routeConfig?.noneTag?.length !== 0
    ) {
      if (
        !routeConfig?.everyTag?.every(
          (tag) => hubSessionContext.tags?.includes(tag.description), //If the user does not have all the tags in the everyTag array, redirect to access denied
        ) &&
        !routeConfig?.someTag?.some(
          (tag) => hubSessionContext.tags?.includes(tag.description), //if the user does not have at least one of the tags in the someTag array, redirect to access denied
        ) &&
        routeConfig?.noneTag?.some(
          (tag) => hubSessionContext.tags?.includes(tag.description), //If the user has at least one of the tags in the noneTag array, redirect to access denied
        )
      ) {
        return <Redirect to="/access-denied" />;
      }
    }
  }
  return (
    <Route
      {...props}
      render={(props) => (
        <RouteConfigWrapper>
          {render ? render(props) : children}
        </RouteConfigWrapper>
      )}
    />
  );
};

export default ProtectedRoute;
