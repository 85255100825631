import PageComponent from "hub/src/components/common/PageComponent";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import { useState } from "react";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { postSocietyAuthRecoverContract } from "shared/api/contracts/society/societyId/auth/recover";
import NewAppLoadingButton from "admin/src/ui/components/common/newform/NewAppLoadingButton";

const AccountRecoverPage = () => {
  const device = useDeviceInfo();
  const [email, setEmail] = useState("");
  const [sentRequest, setSentRequest] = useState<string | null>(null);
  // const recoverAccountMutation = useApiMutation(RecoverAccount, {
  //   societyId: getSocietyId().toString(),
  // });

  const handleSubmit = async () => {
    setSentRequest(email);
    await newApiRequest(postSocietyAuthRecoverContract, {
      params: { societyId: getSocietyId() },
      body: { email },
    });
  };

  return (
    <PageComponent>
      {device.isNativeApp && (
        <PageComponent.Header showBackButton={true}></PageComponent.Header>
      )}

      <PageComponent.Content className="container-light h-full w-full">
        <div
          className="h-full w-full max-w-1/4 min-w-9xl m-auto
          flex flex-col justify-center items-center pl-3 pr-3
          phone:min-w-full phone:max-w-none"
        >
          {/* TODO: This is a placeholder for the society logo, which we currently
        only have access to after authentication. We will need to either fetch
        the society info prior to auth or package the logos with the build
        itself per-society. If we opt not to package with the build, we may not
        be able to include it in the splash screen, but can still include it
        here. Once we get this out of placeholder, these styles should be in common
        or component tokens. The only inline should be the layout utility classes. */}
          <div className="w-12.5 h-12.5 mb-7 rounded-full bg-primary flex justify-center items-center">
            <span className="text-5xl text-extrabold text-neutral-light">
              S
            </span>
          </div>
          <h2 className="mb-4.5">Log In</h2>
          {/* TODO: Replace this with NewAppForm */}
          {sentRequest === email ? (
            <>
              If any accounts are found matching the email address you provided,
              an email will be sent to that address with instructions
            </>
          ) : (
            <form id="login-form" className="flex flex-col w-full">
              <label htmlFor="email">Account Recovery</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <NewAppLoadingButton
                onClick={handleSubmit}
                type="submit"
                className="button-regular-general-filled mt-4"
              >
                Recover
              </NewAppLoadingButton>
            </form>
          )}
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default AccountRecoverPage;
