import { LuxonDateFormats } from "admin/src/utils/helpers/datetime/dateFormats";
import { DateTime } from "luxon";
/**
 * Formats a date using the Luxon library.
 *
 * @param {string|Date} date - The date to format.
 * @param {LuxonDateFormats} format - The format to use.
 * @param {LuxonDateFormats} zone - The time zone to use.
 * @returns {string} The formatted date as string.
 */
export const formatLuxonDate = (
  date: string | Date,
  format: LuxonDateFormats,
  zone?: string,
): string => {
  if (!date) {
    return "";
  }

  const isoDate = DateTime.fromISO(date.toString(), { zone });
  if (!isoDate.isValid) {
    return "";
  }

  return isoDate.toFormat(format);
};
