import { parseBool } from "hub/src/utils/parseBool";

export const ENV = {
  REACT_BASE_PATH: window.REACT_BASE_PATH,
  REACT_APP_PILLAR_API_URL:
    window.REACT_APP_PILLAR_API_URL || process.env.REACT_APP_PILLAR_API_URL,
  REACT_APP_SOCIETY_ID:
    window.REACT_APP_SOCIETY_ID || process.env.REACT_APP_SOCIETY_ID,
  REACT_APP_HIDE_SIDEBAR: parseBool(
    process.env.REACT_APP_HIDE_SIDEBAR,
    window.REACT_APP_HIDE_SIDEBAR,
  ),
} as const;
