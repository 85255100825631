import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
import { PillarPDF } from "admin/src/ui/types/PDF";
import { SocietyProfileInvoiceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
export const getSocietyProfileAccountingInvoiceGeneratePdfContract: BaseApiContract<
SocietyProfileInvoiceParamsInput,
  undefined,
  PillarPDF
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/generate-pdf",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  responseType: undefined as unknown as PillarPDF,
};
