import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import React, { ComponentPropsWithoutRef, useState } from "react";

type NewAppLoadingButtonProps = ComponentPropsWithoutRef<"button"> & {
  onClick: (() => Promise<void>) | (() => void);
  label?: string;
  icon?: React.ReactElement;
  testid?: string;
};

const NewAppLoadingButton = ({
  onClick,
  label,
  icon,
  testid,
  className,
  ...props
}: NewAppLoadingButtonProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const handleClick = async () => {
    setIsButtonLoading(true);
    try {
      await onClick();
      setIsButtonLoading(false);
    } catch (response) {
      setIsButtonLoading(false);
    }
  };

  return (
    <button
      data-testid={testid}
      type="button"
      className={`flex items-center justify-center gap-x-0.5 button-regular-general-filled ${
        className ? className : ""
      }`}
      disabled={isButtonLoading}
      onClick={handleClick}
      {...props}
    >
      {isButtonLoading ? (
        <Spinner spinnerSize={SpinnerSize.Small} />
      ) : (
        <>
          {icon && icon}
          {label && label}
          {props.children}
        </>
      )}
    </button>
  );
};

export default NewAppLoadingButton;
