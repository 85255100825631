import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ErrorMessage } from "formik";
import React, { useState } from "react";

//This is one of the few cases we may NOT want to do ComponentPropsWithoutRef.
//The reason is we use this as a wrapper around other inputs where those inputs
//should really be the ones taking the ComponentPropsWithoutRef<"input">.
export type NewAppInputProps = {
  name: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  additionalClasses?: string;
  children?: React.ReactNode;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  testid?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder?: any; //TODO: STYLING Remove this. It is not used here and should not be defined here.
  required?: boolean; //TODO: STYLING Remove this. It is not used here and should not be defined here.
  collapse?: boolean;
};

const NewAppInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  children,
  testid,
  collapse = false,
}: NewAppInputProps) => {
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <div
      className={`flex flex-col ${additionalClasses ?? ""}`}
      data-testid={testid ? `${testid}-wrapper` : undefined}
    >
      {label && (
        <label
          className={`mb-0.25 ${labelClassName ?? "text-filter-label"} ${
            collapse ? "inline-flex items-center gap-x-0.5 cursor-pointer" : ""
          }`}
          htmlFor={name}
          onClick={() => {
            if (!collapse) return;
            setIsCollapse(!isCollapse);
          }}
        >
          {collapse &&
            (isCollapse ? (
              <ChevronUpIcon className="h-2.5 w-2.5" />
            ) : (
              <ChevronDownIcon className="h-2.5 w-2.5" />
            ))}{" "}
          {label}:
        </label>
      )}
      {collapse ? isCollapse && children : children}
      <ErrorMessage
        name={name}
        component="span"
        className="text-danger-small"
      />
    </div>
  );
};

export default NewAppInput;
