import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppPasswordInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

//TODO: We should rename "additionalClasses" to "containerClasses" or something more descriptive.
const NewAppPasswordInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  testid,
  onChange,
  ...props
}: NewAppPasswordInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={`${additionalClasses ?? ""}`}
    >
      <input
        type="password"
        className={`${className ? className : ""}`}
        value={value}
        name={name}
        id={name}
        data-testid={testid}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppPasswordInput;
