import LoadingPage from "hub/src/components/common/LoadingPage";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { getSocietyId } from "hub/src/utils/getSocietyId";

import { SessionView } from "shared/mappers/database/session/session";
import AblyComponent from "hub/src/components/common/AblyComponent";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { postSocietyProfileLogoutContract } from "shared/api/contracts/society/societyId/profiles/profileId/logout";
import {
  getSocietyAuthContract,
  postSocietyAuthContract,
} from "shared/api/contracts/society/societyId/auth";
import { SocietyAuthBodyInput } from "shared/api/types/society/[societyId]/auth";
import { getSocietyTokenTokenValueContract } from "shared/api/contracts/society/societyId/token/tokenValue";
import { useCustomRouter } from "admin/src/ui/context/CustomRouterContext";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { useHubAbly } from "hub/src/hooks/useHubAbly";
import { useLocation } from "react-router";
import axios from "axios";
interface UserHubSessionContext extends SessionView {
  timezone: string;
  setTimezone: (timezone: string) => void;
  setSession: (session: SessionView | null) => void;
  logout: () => Promise<void>;
  login: (loginDetails: Required<SocietyAuthBodyInput>) => Promise<void>;
  token: (token: string) => Promise<void>;
  refreshSession: () => Promise<void>;
  sessionLoading: boolean;
  queryParams?: RouteParams;
}

const UserHubSessionContext = createContext<UserHubSessionContext>({
  timezone: "system",
  setTimezone: () => undefined,
  setSession: () => undefined,
  logout: async () => undefined,
  login: async () => undefined,
  token: async () => undefined,
  refreshSession: async () => undefined,
  sessionLoading: true,
  environment: "prod",
  queryParams: {},
});

type Props = {
  children: ReactNode;
};

const UserHubSessionProvider = ({ children }: Props) => {
  //Hooks
  // const history = useHistory();
  const [session, setSession] = useState<SessionView | null>(null);
  const [sessionLoading, setSessionLoading] = useState<boolean>(false);

  const router = useCustomRouter();
  const location = useLocation();
  //ContextState
  const [timezone, setTimezone] = useState<string>("system");
  const [redirectUrlAfterLogin, setRedirectUrlAfterLogin] = useState<
    string | null
  >(null);
  const [postUrlAfterLogin, setPostUrlAfterLogin] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if ((location.state as any)?.from?.pathname && !redirectUrlAfterLogin) {
      const locationStateFrom = (location.state as any)?.from;
      setRedirectUrlAfterLogin(
        locationStateFrom?.pathname + locationStateFrom?.search,
      );
    }
  }, [location.state]);

  const queryParams = Array.from(
    new URLSearchParams(location.search).entries(),
  ).reduce((acc: Record<string, string | number | boolean>, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
  useEffect(() => {
    if (queryParams.token) {
      setSessionLoading(true);
      tokenCallback(queryParams.token as string);
    }
    if (queryParams.impersonate) {
      setSessionLoading(true);
      tokenCallback(queryParams.impersonate as string);
    }
    if (queryParams.returnUrl) {
      setPostUrlAfterLogin(queryParams.returnUrl as string);
    }
  }, [queryParams.token, queryParams.impersonate, queryParams.returnUrl]);

  const setSocietyTheme = (theme: any) => {
    theme.forEach((theme: any) => {
      document.documentElement.style.setProperty(
        `--${theme.property}`,
        theme.value,
      );
    });
  };

  const logoutCallback = async () => {
    setSession(null);
    setSessionLoading(true);
    router.push("/login");
    const response = await newApiRequest(postSocietyProfileLogoutContract, {
      params: {
        societyId: getSocietyId(),
        profileId: session!.profileId!,
      },
    });
    await refreshSessionCallback();
  };
  // Remove the call to redirectAfterLoginCallback from refreshSessionCallback
  const refreshSessionCallback = async (tmpRedirectUrlAfterLogin?: string) => {
    try {
      setSessionLoading(true);
      const response = await newApiRequest(getSocietyAuthContract, {
        params: {
          societyId: getSocietyId(),
        },
      });
      setSession(response);
      await redirectAfterLoginCallback(
        response,
        tmpRedirectUrlAfterLogin ?? redirectUrlAfterLogin,
      );
    } catch (error) {
      router.push("/login");
      await refreshSessionCallback();
    }
  };

  const [targetPath, setTargetPath] = useState<string | null>(null);

  useEffect(() => {
    if (targetPath && sessionLoading && targetPath === location.pathname) {
      // Navigation is complete when the location matches the target
      setSessionLoading(false);
    }
  }, [location, sessionLoading, targetPath]);
  const redirectAfterLoginCallback = async (
    session?: SessionView | null,
    redirectUrlAfterLogin?: string | null,
  ) => {
    if (session?.societyUserId) {
      if (redirectUrlAfterLogin) {
        router.push(redirectUrlAfterLogin);
        setTargetPath(redirectUrlAfterLogin);
        setRedirectUrlAfterLogin(null);
      } else if (router.pathname === "/" || router.pathname === "/login") {
        setTargetPath("/overview");
        router.push("/overview");
      }
    } else if (session) {
      setSessionLoading(false);
    }
  };

  const loginCallback = async (
    loginDetails: Required<SocietyAuthBodyInput>,
  ) => {
    try {
      setSessionLoading(true);
      const response = await newApiRequest(postSocietyAuthContract, {
        params: {
          societyId: getSocietyId(),
        },
        body: loginDetails,
      });
      setSession(response);
      // handler for OIDC POST login interaction
      if (postUrlAfterLogin) {
        await axios.post(
          postUrlAfterLogin,
          {
            accountId: response.societyUserId,
          },
          { withCredentials: true },
        );
        setPostUrlAfterLogin(null);
      } else {
        await redirectAfterLoginCallback(response, redirectUrlAfterLogin);
      }
    } catch (error) {
      setSessionLoading(false);
      throw error;
    }
  };

  const tokenCallback = async (tokenValue: string) => {
    const response = await newApiRequest(getSocietyTokenTokenValueContract, {
      params: {
        societyId: getSocietyId()!,
        tokenValue,
      },
    });
    if ("usageLogin" in response) {
      const loginUsage = response.usageLogin!;
      let url = loginUsage.routePath;
      for (const key in loginUsage.pathParam) {
        url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
      }
      setRedirectUrlAfterLogin(url);
      await refreshSessionCallback(url);
    } else {
      await refreshSessionCallback();
    }
  };

  //Watch Session, sometimes we set it to Null and need to refresh it.
  //I dont know why we need to refresh it, but we did? - GS 11/18/24 6:44PM
  useEffect(() => {
    if (!session && !sessionLoading) {
      refreshSessionCallback();
    }
    if (session?.society && session.society.theme) {
      setSocietyTheme(session.society.theme);
    }
  }, [session, sessionLoading]);

  //The juice of the context
  const value = useMemo(
    () => ({
      timezone,
      setTimezone,
      setSession,
      logout: logoutCallback,
      login: loginCallback,
      token: tokenCallback,
      refreshSession: refreshSessionCallback,
      environment: session?.environment ?? "prod",
      societyUser: session?.societyUser ?? undefined,
      society: session?.society ?? undefined,
      profile: session?.profile ?? undefined,
      societyAdmin: session?.societyAdmin ?? undefined,
      iat: session?.iat ?? 0,
      authInvalidBefore: session?.authInvalidBefore ?? "",
      societyUserId: session?.societyUserId ?? undefined,
      societyAdminId: session?.societyAdminId ?? undefined,
      adminMode: session?.adminMode ?? false,
      profileId: session?.profileId ?? undefined,
      societyId: session?.societyId ?? undefined,
      societies: session?.societies ?? [],
      profileIds: session?.profileIds ?? [],
      tags: session?.tags ?? [],
      sessionLoading,
      queryParams,
    }),
    [
      timezone,
      setTimezone,
      logoutCallback,
      loginCallback,
      tokenCallback,
      refreshSessionCallback,
      session?.societyUserId,
      session?.profileId,
      session?.societyAdminId,
      session,
      queryParams,
      sessionLoading,
    ],
  );
  const ablyReady = useHubAbly();

  if (!session?.societyId) {
    return <LoadingPage />;
  }

  if (ablyReady) {
    return (
      <UserHubSessionContext.Provider value={value}>
        <AblyComponent>{children}</AblyComponent>
      </UserHubSessionContext.Provider>
    );
  }
  return (
    <UserHubSessionContext.Provider value={value}>
      {children}
    </UserHubSessionContext.Provider>
  );
};

export { UserHubSessionContext, UserHubSessionProvider };
