import {
  MembershipView,
  ExtendedMembershipView,
} from "shared/mappers/database/profile/membership";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { LuxonDateFormats } from "admin/src/utils/helpers/datetime/dateFormats";
import { formatLuxonDate } from "admin/src/utils/helpers/datetime/formatLuxonDate";
import { ConvertToDate } from "admin/src/utils/helpers/datetime/getDate/ConvertToDate";

type FormattedMembershipDataReturnType = MembershipView &
  ExtendedMembershipView & {
    [key: string]: string | undefined;
  };

export const getFormattedMembershipData = (
  profile: ProfileView,
): FormattedMembershipDataReturnType => {
  const currentDate = new Date();
  const membershipInfo: MembershipView = {
    name: profile?.latestMembership?.name ?? "Non-Member",
    effectiveDate: profile?.latestMembership?.effectiveDate
      ? formatLuxonDate(
          profile.latestMembership.effectiveDate,
          LuxonDateFormats.D,
        )
      : undefined,
    expirationDate: profile?.latestMembership?.expirationDate
      ? formatLuxonDate(
          profile.latestMembership.expirationDate,
          LuxonDateFormats.D,
        )
      : undefined,
  };
  const extraMembershipInfo = {
    terminationDate:
      membershipInfo.expirationDate &&
      ConvertToDate(membershipInfo.expirationDate) < currentDate
        ? formatLuxonDate(membershipInfo.expirationDate, LuxonDateFormats.D)
        : undefined,
    isMember: profile.latestMembership?.name,
  };
  return { ...membershipInfo, ...extraMembershipInfo };
};
