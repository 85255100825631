import { CUSTOM_TYPE_PROFILE_EDUCATION } from "admin/src/ui/components/surveyJS/shared/education/ProfileEducationQuestionComponent";
import { SessionView } from "shared/mappers/database/session/session";
import { Serializer } from "survey-core";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
} from "survey-core";

export const ProfileEducationMultipleQuestionProperties = {
  educationInstanceId: "educationInstanceId",
  hoursToUse: "hoursToUse",
};

const CUSTOM_TYPE_PROFILE_EDUCATION_MULTI = "profile_education_multi";

export const registerPropertiesProfileEducationMultipleQuestion = () => {
  Serializer.addProperty(CUSTOM_TYPE_PROFILE_EDUCATION_MULTI, {
    name: "hoursRequired",
    displayName: "Require hours for certification",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "General",
  });
};

export type ProfileEducationMultipleQuestionProps = {
  session: SessionView;
};

export const ProfileEducationMultipleQuestion = async ({
  session,
}: ProfileEducationMultipleQuestionProps) => {
  registerPropertiesProfileEducationMultipleQuestion();

  const educationInstanceConfig: ICustomQuestionTypeConfiguration = {
    name: CUSTOM_TYPE_PROFILE_EDUCATION_MULTI,
    defaultQuestionTitle: "Select Education Instance and Hours to Use",
    questionJSON: {
      type: "paneldynamic",
      name: "education_instance_panel",
      title: "Education Instance Selection",
      templateElements: [
        {
          type: CUSTOM_TYPE_PROFILE_EDUCATION,
          name: CUSTOM_TYPE_PROFILE_EDUCATION,
          title: "Education Instance",
          startWithNewLine: false,
          isRequired: true,
          requiredErrorText: "You must select a education instance.",
        },
      ],
    },
    onInit: () => {
      registerPropertiesProfileEducationMultipleQuestion();
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      CUSTOM_TYPE_PROFILE_EDUCATION_MULTI,
    )
  ) {
    ComponentCollection.Instance.add(educationInstanceConfig);
  }
};
