export const parseBool = (
  value: string | undefined,
  _default: boolean,
): boolean => {
  try {
    const parsed = JSON.parse(value || "null");

    if (typeof parsed === "boolean") return parsed;

    return _default;
  } catch {
    return _default;
  }
};
