import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
import { LogoutResponse } from "shared/api/types/society/[societyId]/profiles/[profileId]/logout";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyProfileLogoutContract: BaseApiContract<
  SocietyProfileParamsInput,
  undefined,
  LogoutResponse
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]/logout",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as LogoutResponse,
};
