import { useEffect, useState } from "react";
import { configureAbly } from "@ably-labs/react-hooks";
import { fetchApiQuery, GetAblyTokenCreation } from "hub/src/api";
import { initializePushNotifications } from "hub/src/utils/firebase/pushNotification";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";

export const useHubAbly = () => {
  const [ablyReady, setAblyReady] = useState<boolean>(false);
  const session = useHubSessionContext();
  useEffect(() => {
    const ably = configureAbly({
      autoConnect: false,
      authCallback: async (data, callback) => {
        try {
          const response = await fetchApiQuery(
            GetAblyTokenCreation,
            { societyId: getSocietyId().toString() },
            {},
          );
          callback(null, response.data.body!);
          setAblyReady(true);
        } catch (error) {
          // console.error(error);
          // throw new Error("Unable to get Ably's Token.");
        }
      },
    });

    if (
      session?.societyUserId &&
      session?.society?.societySettingsPublic?.ablyEnabled &&
      !ablyReady
    ) {
      initializePushNotifications();
      ably.connect();
    }

    // We want to clean up our connections,  but also this is breaking things so
    // TODO: Fix this ~ GS and Silvestre 7/21/23 1:22PM
    // return () => {
    //   console.log("unmounting");
    //   //      ably.close();
    // };
  }, [session?.societyUserId, session?.society, ablyReady]);
  return ablyReady;
};
