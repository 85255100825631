import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
import { EducationInstanceView } from "shared/mappers/database/education/education-instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { PaginatedResults } from "admin/src/ui/types/common/general-types";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const getSocietyProfileEducationInstanceContract: BaseApiContract<
  SocietyProfileParamsInput,
  undefined,
  PaginatedResults<EducationInstanceView>
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/education/instance",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as PaginatedResults<EducationInstanceView>,
};
