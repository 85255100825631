import { ChoicesQuestionNameParamsInput } from "shared/api/types/society/[societyId]/choices/[questionName]/index";
import { SocietyCustomChoiceDto } from "shared/mappers/database/society-custom-choices/societyCustomChoice";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { PaginatedResults } from "admin/src/ui/types/common/general-types";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const getSocietyChoicesQuestionNameContract: BaseApiContract<
  ChoicesQuestionNameParamsInput,
  undefined,
  PaginatedResults<SocietyCustomChoiceDto>
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/choices/[questionName]",
  paramsType: {} as ChoicesQuestionNameParamsInput,
  responseType: {} as PaginatedResults<SocietyCustomChoiceDto>,
};

export const deleteSocietyChoicesQuestionNameContract: BaseApiContract<
  ChoicesQuestionNameParamsInput,
  undefined,
  void
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/choices/[questionName]",
  paramsType: {} as ChoicesQuestionNameParamsInput,
  responseType: undefined as unknown as any,
};
