import { ComponentPropsWithoutRef } from "react";

type ChevronRightIconProps = ComponentPropsWithoutRef<"svg"> & {
  width?: number;
  height?: number;
};

const ChevronRightIcon = (props: ChevronRightIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-chevron-right"
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="9 6 15 12 9 18" />
    </svg>
  );
};

export default ChevronRightIcon;
