import { FormikValues, useFormikContext } from "formik";

import { get } from "lodash";
import React, { ComponentPropsWithoutRef } from "react";

import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppNumberInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type"> & {
    hideArrows?: boolean;
  };

const NewAppNumberInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  hideArrows,
  maxLength,
  defaultValue,
  onChange,
  ...props
}: NewAppNumberInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <input
        type="number"
        // Arbitrary classes are required here because tailwind doesn't have any classes to hide default input type number behaviour
        className={`${className} ${
          hideArrows &&
          "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        }`}
        onInput={(event) => {
          const input = event.target as HTMLInputElement;
          if (!maxLength) {
            return input;
          }

          input.value = input.value.slice(0, maxLength);
          return input;
        }}
        value={value}
        defaultValue={defaultValue}
        placeholder={props.placeholder?.toString()}
        name={name}
        id={name}
        data-testid={props.testid}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          onChange?.(e);
        }}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppNumberInput;
