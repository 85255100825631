/* eslint-disable @typescript-eslint/no-explicit-any */
import SurveyJSProfileEducation, {
  SurveyJSProfileEducationProps,
} from "admin/src/ui/components/surveyJS/components/SurveyJSProfileEducation";
import React from "react";
import { SessionView } from "shared/mappers/database/session/session";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";

export const CUSTOM_TYPE_PROFILE_EDUCATION = "profile_education";

export class ProfileEducationQuestionModel extends Question {
  getType() {
    return CUSTOM_TYPE_PROFILE_EDUCATION;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_PROFILE_EDUCATION
] = "icon-search";

export const NewProfileEducationQuestionProperties = {
  hoursRequired: "hoursRequired",
};

export type NewProfileEducationQuestionInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
  session: SessionView;
  baseUrl: string;
};

export const NewProfileEducationQuestion = (
  props: NewProfileEducationQuestionInputProps,
) => {
  props.question.titleLocation = "hidden";
  props.question.errorLocation = "bottom";

  const existingValue = props.question.value;
  const hoursRequired = props.question.getPropertyValue(
    NewProfileEducationQuestionProperties.hoursRequired,
  );

  return SurveyJSProfileEducation({
    handleChanged: (val) => {
      props.question.value = val;
    },
    session: props.session,
    existingValue,
    hoursRequired,
  } as SurveyJSProfileEducationProps);
};

export const RegisterProfileEducationQuestionComponent = (
  session: SessionView,
  baseUrl?: string,
) => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_PROFILE_EDUCATION,
    (name) => {
      return new ProfileEducationQuestionModel(name);
    },
  );

  Serializer.addClass(
    CUSTOM_TYPE_PROFILE_EDUCATION,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new ProfileEducationQuestionModel("");
    },
    "question",
  );

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_EDUCATION, {
    name: NewProfileEducationQuestionProperties.hoursRequired,
    displayName: "Require hours for certification",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "General",
  });

  Serializer.getProperty(CUSTOM_TYPE_PROFILE_EDUCATION, "name").readOnly = true;

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_PROFILE_EDUCATION,
    (props: any) => {
      return React.createElement(NewProfileEducationQuestion, {
        ...props,
        session,
        baseUrl,
      });
    },
  );
};
