import { Prisma, society_setting_name } from "@prisma/client";
export const requiredAdminSettings = {
  rangeProductCutoffDay: society_setting_name.range_product_cutoff_day,
  rangeProductCutoffMonth: society_setting_name.range_product_cutoff_month,
  authorizeTransactionKey: society_setting_name.authorize_transaction_key,
  authorizeDotNetApiUrl: society_setting_name.authorize_dot_net_api_url,
  authorizeDotNetApiUrlAcceptSuite:
    society_setting_name.authorize_dot_net_api_url_accept_suite,
  ablyEnabled: society_setting_name.ably_enabled,
  awsSecretKeys: society_setting_name.aws_secret_keys,
  confirmProfileOlderThanDays:
    society_setting_name.confirm_profile_older_than_days,
  authorizePublicClient: society_setting_name.authorize_public_client,
  authorizeMerchantName: society_setting_name.authorize_merchant_name,
  awsConfig: society_setting_name.aws_config,
  accountiungMsTeamsNotificationWebhookUrl:
    society_setting_name.accounting_ms_teams_notification_webhook_url,
  pillarProfileOverviewInstitution:
    society_setting_name.pillar_profile_overview_institution,
  googleMapsGeocodeEnabled: society_setting_name.google_maps_geocode_enabled,
  updatePasswordFormId: society_setting_name.update_password_form_id,
  defaultEmailSender: society_setting_name.default_email_sender,
  defaultUpdatePasswordEmailTemplate:
    society_setting_name.default_update_password_email_template,
  defaultInvoiceEmailTemplate:
    society_setting_name.default_invoice_email_template,
  userhubUrl: society_setting_name.userhub_url,
  userHubOverviewInstitution:
    society_setting_name.user_hub_overview_institution,
  userHubOverviewSocietyUser:
    society_setting_name.user_hub_overview_society_user,
  societyLoginLogoUrl: society_setting_name.society_login_logo_url,
  hubPaymentTypes: society_setting_name.hub_payment_types,
  automaticInvoiceIgnoreExpirationSkipMissed:
    society_setting_name.automatic_invoice_ignore_expiration_skip_missed,
  pillarProfileOverviewSocietyUser:
    society_setting_name.pillar_profile_overview_society_user,
  membershipEnabled: society_setting_name.membership_enabled,
  membershipCardEnabled: society_setting_name.membership_card_enabled,
  fiscalYearStartMonth: society_setting_name.fiscal_year_start_month,
  fiscalYearStartDay: society_setting_name.fiscal_year_start_day,
  financeRecognizeDayOfMonth:
    society_setting_name.finance_recognize_day_of_month,
  invoiceReceiptHtmlTemplate:
    society_setting_name.invoice_receipt_html_template,
  pdfInvoice: society_setting_name.pdf_invoice,
};

export const adminSocietySettingsIncludesWhere = () => {
  return Prisma.validator<Prisma.SocietySettingAdminWhereInput>()({
    OR: Object.values(requiredAdminSettings).map((setting) => {
      return {
        name: setting,
      };
    }),
  });
};
