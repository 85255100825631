import { SocietyParamsInput } from "shared/api/types/society/[societyId]";
import { AuthRecoverBodyInput } from "shared/api/types/society/[societyId]/auth/recover";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
export const postSocietyAuthRecoverContract: BaseApiContract<
  SocietyParamsInput,
  AuthRecoverBodyInput,
  void
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/auth/recover",
  paramsType: {} as SocietyParamsInput,
  bodyType: {} as AuthRecoverBodyInput,
  responseType: undefined as unknown as void,
};
