import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppTextInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

//TODO: We should rename "additionalClasses" to "containerClasses" or something more descriptive.
const NewAppTextInput = forwardRef<HTMLInputElement, NewAppTextInputProps>(
  (
    {
      name,
      label,
      labelClassName,
      additionalClasses,
      className,
      testid,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const value = get(values, name);
    return (
      <NewAppInput
        name={name}
        labelClassName={labelClassName}
        label={label}
        additionalClasses={`${additionalClasses ?? ""}`}
      >
        <input
          type="text"
          className={`${className ? className : ""}`}
          value={value}
          name={name}
          id={name}
          data-testid={testid}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          ref={ref}
          {...props}
        />
      </NewAppInput>
    );
  },
);

NewAppTextInput.displayName = "NewAppTextInput";

export default NewAppTextInput;
